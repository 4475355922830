import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import settingReducer from "./setting/reducers";

//Login
import userSlice from "./login/loginSlice";
import userProfileSlice from "./profile/userProfileSlice";

import dashboardDataSlice from "./dashboard/dashboardDataSlice";
import recentScanListSlice from "./dashboard/recentScanListSlice";

//Users
import userListSlice from "./users/userListSlice";
import createUserSlice from "./users/createUserSlice";
import userDetailsSlice from "./users/userDetailsSlice";
import userEditSlice from "./users/userEditSlice";
import userDeleteSlice from "./users/userDeleteSlice";
import newRequestListSlice from "./users/newRequestListSlice";
import userRequestVerifySlice from "./users/userRequestVerifySlice";
import userPointHistoryListSlice from "./users/userPointHistoryListSlice";
import userPointsSlice from "./users/userPointsSlice";
import userPointRedeemSlice from "./users/userPointRedeemSlice";
import userQRScansListSlice from "./users/userQRScansListSlice";
import redeemPointSlice from "./users/redeemPointSlice";

//QR Codes
import barcodeGroupListSlice from "./barcode/barcodeGroupListSlice";
import barcodeListSlice from "./barcode/barcodeListSlice";
import barcodeAddSlice from "./barcode/barcodeAddSlice";
import recentBarcodeListSlice from "./barcode/recentBarcodeListSlice";
import printBarcodeListSlice from "./barcode/printBarcodeListSlice";
import printBatchQRSlice from "./barcode/printBatchQRSlice";
import barcodeEditSlice from "./barcode/barcodeEditSlice";

//Staff
import staffDeleteSlice from "./usermanagement/staff/staffDeleteSlice";
import staffListSlice from "./usermanagement/staff/staffListSlice";
import staffDetailsSlice from "./usermanagement/staff/staffDetailsSlice";
import staffEditSlice from "./usermanagement/staff/staffEditSlice";
import staffAddSlice from "./usermanagement/staff/staffAddSlice";

//Points
import allPointListSlice from "./points/allPointListSlice";

//Recent Scan
import recentAllScanListSlice from "./recentScans/recentAllScanListSlice";
import verifyRecentScanSlice from "./recentScans/verifyRecentScanSlice";

//Rejected Scans
import rejectedScanSlice from "./rejectedScans/rejectedScanSlice";

//Approved Scans
import approvedAllScanListSlice from "./approvedScans/approvedAllScanListSlice";

//Business Details
import businessDetailsSlice from "./systemSettings/business/businessDetailsListSlice";
import businessEditSlice from "./systemSettings/business/businessDetailsEditSlice";

//Reject Reasons
import reasonListSlice from "./systemSettings/rejectReasons/reasonListSlice";
import reasonDeleteSlice from "./systemSettings/rejectReasons/reasonDeleteSlice";
import reasonPostSlice from "./systemSettings/rejectReasons/reasonPostSlice";
import reasonDetailsSlice from "./systemSettings/rejectReasons/reasonDetailsSlice";
import reasonEditSlice from "./systemSettings/rejectReasons/reasonEditSlice";

//Redeem Requests
import redeemRequestListSlice from "./redeemRequests/redeemRequestListSlice";
import verifyRedeemRequestSlice from "./redeemRequests/verifyRedeemRequestSlice";


export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    setting: settingReducer,
    userSlice,
    //Staff
    staffListSlice,
    staffAddSlice,
    staffDetailsSlice,
    staffDeleteSlice,
    staffEditSlice,
    //Settings
    businessDetailsSlice,
    businessEditSlice,

    //Users
    userListSlice,
    createUserSlice,
    userDetailsSlice,
    userEditSlice,
    userDeleteSlice,
    newRequestListSlice,
    userRequestVerifySlice,
    userPointHistoryListSlice,
    userPointsSlice,
    userPointRedeemSlice,
    userQRScansListSlice,
    redeemPointSlice,
    //Barcode
    barcodeGroupListSlice,
    barcodeListSlice,
    barcodeAddSlice,
    recentBarcodeListSlice,
    printBarcodeListSlice,
    printBatchQRSlice,
    barcodeEditSlice,
    //Points
    allPointListSlice,
    //Recent Scan
    recentAllScanListSlice,
    verifyRecentScanSlice,
    //Rejected Scans
    rejectedScanSlice,
    //Dashboard 
    dashboardDataSlice,
    recentScanListSlice,
    userProfileSlice,
    //Reject Reasons
    reasonListSlice,
    reasonDeleteSlice,
    reasonPostSlice,
    reasonDetailsSlice ,
    reasonEditSlice,
    approvedAllScanListSlice,
    //Redeem Requests
    redeemRequestListSlice,
    verifyRedeemRequestSlice,
  },
});
